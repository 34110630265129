import { Box } from "components/shared/box"
import { Section } from "components/shared/landing/section"

export function NumbersSection() {
  return (
    <Section className="mt-20" header="Cryptobots Game">
      <Box className="flex-col md:flex-row gap-8 justify-around">
        <Box center>
          <div className="text-saira text-[2.5rem] leading-[2.5rem] text-primary text-shadow-without-blur-lg">
            100 000+
          </div>
          <div className="mt-2 text-table font-semibold">Community Members</div>
        </Box>

        <Box center>
          <div className="text-saira text-[2.5rem] leading-[2.5rem] text-primary text-shadow-without-blur-lg">
            10 000
          </div>
          <div className="mt-2 text-table font-semibold">Genesis Bots Sold</div>
        </Box>

        <Box center>
          <div className="text-saira text-[2.5rem] leading-[2.5rem] text-primary text-shadow-without-blur-lg">
            250 000+
          </div>
          <div className="mt-2 text-table font-semibold">Battles on Alpha</div>
        </Box>
      </Box>
    </Section>
  )
}
