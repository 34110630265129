import React from "react"

export function BitsCircleIcon({ h = 32, w = 32, className = "" }: { h?: number; w?: number; className?: string }) {
  return (
    <svg width={w} height={h} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="url(#paint0_linear_11994_84942)" />
      <path
        d="M15.9987 20.0121C18.4504 20.0121 20.4379 18.0246 20.4379 15.5729C20.4379 13.1213 18.4504 11.1338 15.9987 11.1338C13.547 11.1338 11.5596 13.1213 11.5596 15.5729C11.5596 18.0246 13.547 20.0121 15.9987 20.0121Z"
        fill="white"
      />
      <path
        d="M22.7568 7.23846C22.7312 7.21792 22.7017 7.20263 22.6702 7.19348C22.6386 7.18433 22.6055 7.18149 22.5729 7.18513C22.5402 7.18877 22.5086 7.19881 22.4798 7.21468C22.451 7.23055 22.4257 7.25194 22.4052 7.27763L20.5827 9.55679C20.5413 9.60844 20.5222 9.67436 20.5293 9.74012C20.537 9.80571 20.5702 9.86563 20.6218 9.90679C21.824 10.8657 22.6983 12.1746 23.1238 13.6523C23.5494 15.1299 23.5051 16.7034 22.9971 18.1548C22.4892 19.6062 21.5426 20.8638 20.2884 21.7536C19.0343 22.6434 17.5346 23.1214 15.9969 23.1214C14.4591 23.1214 12.9594 22.6434 11.7053 21.7536C10.4511 20.8638 9.50454 19.6062 8.99656 18.1548C8.48858 16.7034 8.4443 15.1299 8.86985 13.6523C9.2954 12.1746 10.1697 10.8657 11.3719 9.90679C11.4235 9.86563 11.4567 9.80571 11.4644 9.74012C11.4715 9.67436 11.4524 9.60844 11.411 9.55679L9.59353 7.27763C9.57303 7.25194 9.54766 7.23055 9.51888 7.21468C9.49011 7.19881 9.45848 7.18877 9.42582 7.18513C9.39316 7.18149 9.3601 7.18433 9.32853 7.19348C9.29697 7.20263 9.26752 7.21792 9.24187 7.23846C7.96761 8.25121 6.93903 9.53916 6.23318 11.0058C5.52733 12.4725 5.16251 14.0799 5.16604 15.7076C5.16604 21.6818 10.0269 26.5409 15.9993 26.5409C21.9718 26.5409 26.8327 21.6801 26.8327 15.7076C26.8362 14.0799 26.4714 12.4725 25.7655 11.0058C25.0597 9.53916 24.0311 8.25121 22.7568 7.23846Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11994_84942"
          x1="0"
          y1="0"
          x2="6.84462e-07"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F1DFF" />
          <stop offset="1" stopColor="#FF4FED" />
        </linearGradient>
      </defs>
    </svg>
  )
}
