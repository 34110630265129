import React from "react"

export function Crown({ h, w, className }: { h?: number; w?: number; className?: string }) {
  return (
    <svg width={w} height={h} className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 60 60">
      <path
        d="M47.0276 37.274L52.9674 18.8872C53.051 18.6294 53.0533 18.352 52.9739 18.0929C52.8945 17.8337 52.7373 17.6052 52.5236 17.4385C52.3098 17.2717 52.05 17.1748 51.7793 17.1608C51.5086 17.1468 51.2401 17.2165 51.0104 17.3603L40.0886 24.197C39.9322 24.2945 39.7571 24.3582 39.5745 24.384C39.392 24.4098 39.2061 24.3971 39.0287 24.3469C38.8514 24.2966 38.6865 24.2097 38.5447 24.092C38.4028 23.9742 38.2872 23.8281 38.2052 23.663L31.1991 9.53395C31.0898 9.31357 30.9211 9.12809 30.712 8.99842C30.503 8.86875 30.2619 8.80005 30.0159 8.80005C29.7699 8.80005 29.5288 8.86875 29.3198 8.99842C29.1107 9.12809 28.9421 9.31357 28.8328 9.53395L21.8218 23.663C21.7397 23.8274 21.6243 23.9729 21.4828 24.0902C21.3414 24.2075 21.177 24.2941 21.0003 24.3443C20.8235 24.3946 20.6382 24.4074 20.4562 24.3821C20.2742 24.3567 20.0995 24.2937 19.9432 24.197L9.02144 17.3683C8.79168 17.2245 8.52323 17.1548 8.25253 17.1688C7.98182 17.1828 7.72197 17.2797 7.50826 17.4465C7.29455 17.6132 7.13732 17.8417 7.05792 18.1009C6.97852 18.36 6.9808 18.6374 7.06445 18.8952L13.0042 37.274C13.0664 37.4671 13.1883 37.6356 13.3524 37.7551C13.5164 37.8746 13.7141 37.939 13.9171 37.9391H46.1147C46.3177 37.939 46.5154 37.8746 46.6794 37.7551C46.8435 37.6356 46.9654 37.4671 47.0276 37.274Z"
        fill="white"
      />
      <path
        d="M14.1617 41.2358H45.8701C46.1245 41.2358 46.3685 41.3369 46.5484 41.5168C46.7283 41.6967 46.8294 41.9407 46.8294 42.1951V45.6023C46.8294 46.4504 46.4925 47.2637 45.8928 47.8634C45.2931 48.4631 44.4797 48.8 43.6317 48.8H16.4001C15.552 48.8 14.7387 48.4631 14.139 47.8634C13.5393 47.2637 13.2024 46.4504 13.2024 45.6023V42.1951C13.2024 41.9407 13.3035 41.6967 13.4834 41.5168C13.6633 41.3369 13.9073 41.2358 14.1617 41.2358Z"
        fill="white"
      />
      <path
        d="M34.4112 27.1916L31.044 22.4542C30.9276 22.2899 30.7735 22.1559 30.5947 22.0634C30.4158 21.9709 30.2173 21.9226 30.016 21.9226C29.8146 21.9226 29.6162 21.9709 29.4373 22.0634C29.2584 22.1559 29.1043 22.2899 28.9879 22.4542L25.6207 27.1916C25.407 27.4924 25.2922 27.8523 25.2922 28.2212C25.2922 28.5902 25.407 28.9501 25.6207 29.2509L28.9879 33.9883C29.1043 34.1526 29.2584 34.2866 29.4373 34.3791C29.6162 34.4716 29.8146 34.5199 30.016 34.5199C30.2173 34.5199 30.4158 34.4716 30.5947 34.3791C30.7735 34.2866 30.9276 34.1526 31.044 33.9883L34.4112 29.2509C34.6249 28.9501 34.7397 28.5902 34.7397 28.2212C34.7397 27.8523 34.6249 27.4924 34.4112 27.1916Z"
        fill="#FF46AA"
      />
    </svg>
  )
}
