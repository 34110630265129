import { addDays, format } from "date-fns"
import Image from "next/image"
import { useRouter } from "next/router"
import React, { useMemo } from "react"
import { Link as ScrollLink } from "react-scroll"
import { trackLoginClick } from "services/analytics"

import { Links } from "config/links"

import { useClientSettings } from "components/hooks/use-client-settings"
import { useTailwindBreakpoints } from "components/hooks/use-tailwind-breakpoints"

import { BackgroundImage } from "components/shared/background-img"
import { Box } from "components/shared/box"
import { ClippedBox } from "components/shared/clipped-box/clipped-box"
import { BitsCircleIcon } from "components/shared/icons/bits-circle-icon"
import { Crown } from "components/shared/icons/crown"
import { Lighter } from "components/shared/icons/lighter"
import { Section } from "components/shared/landing/section"
import { Link } from "components/shared/link"

import ClippedCorners from "utils/clipped-corners"

import scrollImg from "/public/images/pages/index/top-section/scroll-bottom.webp"

export function TopSection({ scrollTo }: { scrollTo: string }) {
  const { isMobile } = useTailwindBreakpoints()
  const { pathname } = useRouter()
  const isLanding = useMemo(() => pathname === "/landing", [pathname])
  const { apy_best_player, apy_vl } = useClientSettings()

  return (
    <BackgroundImage
      imageSrc="/images/pages/index/top-section/bg_top.webp"
      size={isMobile ? "bg-auto" : "bg-cover"}
      className="bg-top bg-no-repeat"
    >
      <Section className="relative md:mb-4 lg:max-h-[100vh]">
        <Box full className="mt-36 justify-between md:flex-row">
          <Box>
            <Box className="mx-auto rounded-full bg-gradient-to-r from-[#FFBF05] to-[#FF07A4] p-[2px] md:ml-0 md:mr-auto">
              <Box className="rounded-full bg-[#8F8BCF] px-6 py-2.5 text-[1.5rem] font-bold uppercase leading-none">
                {isLanding ? "Free NFT Battler" : "play nft game"}
              </Box>
            </Box>

            <Box className="text-shadow-lg z-1 mt-4 text-center text-[3.5rem] font-bold uppercase leading-none leading-none md:text-left md:text-[5rem]">
              {isLanding ? (
                <>
                  FIGHT TO
                  <br />
                  WIN NFT
                </>
              ) : (
                <>
                  THE GAME
                  <br />
                  IS LIVE
                </>
              )}
            </Box>

            {/* <Box className="text-center md:text-left mt-4 uppercase leading-tight font-bold text-shadow-lg z-1 text-[1.25rem]">
              daily entry game limit
              <br />
              for new players
            </Box> */}

            <Box className="mx-auto mt-10 md:ml-0 md:mr-auto">
              <Link
                href={Links.GAME}
                highlightOnHover={false}
                withRedirect
                onClick={() => {
                  trackLoginClick("onpage")
                }}
              >
                <Box
                  style={{
                    boxShadow:
                      "0px 4px 16px rgba(255, 102, 81, 0.5), inset 0px -1px 1px rgba(180, 16, 46, 0.25), inset 0px 1px 1px rgba(255, 255, 255, 0.5)",
                  }}
                  className="text-shadow-without-blur w-full rounded-sm bg-gradient-to-r from-[#FFB80C] to-[#FF109D] px-8 py-3.5 font-semibold uppercase md:w-auto"
                >
                  {isLanding ? "Play Now" : "PLAY GAME"}
                </Box>
              </Link>
            </Box>

            <ClippedBox
              corners={ClippedCorners.rb}
              className="mr-auto mt-24 hidden rounded-box bg-[#7542BF] p-[2px] md:flex"
            >
              <ClippedBox corners={ClippedCorners.rb}>
                <BackgroundImage
                  imageSrc="/images/pages/index/top-section/bg_how_to_play.webp"
                  className="rounded-sm py-6 pl-10 pr-32"
                >
                  <Box className="text-[1.25rem] font-semibold uppercase leading-[1.5rem]">
                    {isLanding ? (
                      <>
                        Join now to <br /> grab your free nft bots
                      </>
                    ) : (
                      <>
                        NEW to PLAY
                        <br />
                        TO EARN games?
                      </>
                    )}
                  </Box>
                  <Link className="mt-2 flex font-semibold underline" href={Links.HOW_TO_START}>
                    Learn more
                  </Link>
                </BackgroundImage>
              </ClippedBox>
            </ClippedBox>
          </Box>
          <Box className="mt-60 justify-end">
            <Link className="hover:text-white" href={Links.STAKE} target="_blank">
              <Box
                center
                className="relative gap-6 overflow-hidden rounded-box bg-[#AD00FF80] px-6 py-4 pr-9 text-white backdrop-blur-2xl"
              >
                <Lighter className="absolute left-0 right-0 top-0 -z-1 w-full" />
                <Lighter color="purple" className="absolute bottom-0 left-0 right-0 -z-1 w-full scale-y-[-1]" />
                <Box row className="w-full">
                  <Box center className="mr-5">
                    <Crown w={34} h={34} />
                  </Box>
                  <Box className="flex-1 text-lg uppercase">
                    Best Player
                    <br />
                    <strong>APY {apy_best_player}%</strong>
                  </Box>
                </Box>
                <Box row className="w-full">
                  <Box center className="mr-5">
                    <BitsCircleIcon w={34} h={34} />
                  </Box>
                  <Box className="flex-1 text-lg uppercase">
                    Vote lock
                    <br />
                    <strong>APY {apy_vl}%</strong>
                  </Box>
                </Box>
              </Box>
            </Link>
            <Box className="mb-6 mt-2 text-center text-[9px] font-semibold uppercase">
              * Based on the data, nfts and token rates for {format(addDays(new Date(), -1), "dd.MM")}
            </Box>
          </Box>

          <ScrollLink
            className="mx-auto mt-4 flex w-4 cursor-pointer md:absolute md:bottom-15 md:left-[calc(50%-0.125rem)]"
            to={scrollTo}
            duration={1000}
            smooth={true}
          >
            <Image src={scrollImg} alt="Scroll down icon" />
          </ScrollLink>
        </Box>
      </Section>
    </BackgroundImage>
  )
}
