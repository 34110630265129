import Image from "next/image"
import { useRouter } from "next/router"
import { useMemo } from "react"

import { Links } from "config/links"

import { Box } from "components/shared/box"
import { ClippedBox } from "components/shared/clipped-box/clipped-box"
import { ClippedCard } from "components/shared/landing/clipped-card"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"

import clippedCorners from "utils/clipped-corners"

import economyImg from "/public/images/pages/index/economy-section/economy.webp"

export function EconomySection() {
  const { pathname } = useRouter()
  const isLanding = useMemo(() => pathname === "/landing", [pathname])

  return (
    <ClippedCard row full className="flex-col lg:justify-between lg:flex-row-reverse shadow-card-point">
      <Box className="flex-1 justify-center text-left lg:mr-4 text-white lg:mt-0">
        <Text as="h2">Grow Your Empire {!isLanding && "to Earn"}</Text>
        <p className="flex flex-col text-base text-white opacity-80 mt-4 lg:mt-6">
          <span>
            Develop your economic strategy — utilize your NFT Lands to {isLanding ? "gain" : "earn profits"} by
            producing resources, providing services, and more!
          </span>
          <Link href={Links.PLANETS} className="mt-6">
            <span className="underline cursor-pointer">Explore Lands</span>
          </Link>
        </p>
      </Box>
      <Box className="flex-1 lg:flex-none lg:mr-10 w-full lg:w-auto mt-6 lg:mt-0">
        <Box className="flex-1 w-full lg:w-auto">
          <ClippedBox
            corners={clippedCorners.lb_rt}
            className="relative mr-auto rounded-lg w-full lg:w-[25rem] h-[15rem] overflow-hidden self-end shadow-md"
          >
            <Image src={economyImg} quality={100} objectFit="cover" layout="fill" alt="battle image" />
          </ClippedBox>
        </Box>
      </Box>
    </ClippedCard>
  )
}
