import React from "react"

import { Links } from "config/links"

import { useModal } from "components/hooks/use-modal"

import { VideoModal } from "components/modals/video-modal"
import { BackgroundImage } from "components/shared/background-img"
import { Box } from "components/shared/box"
import { PlayVideoButton } from "components/shared/icons/play-video-button"
import { Section } from "components/shared/landing/section"
import { Link } from "components/shared/link"
import { Text } from "components/shared/text"

export function AddictiveSection() {
  const { modalIsOpen, openModal, closeModal } = useModal()

  return (
    <>
      {modalIsOpen && (
        <VideoModal
          title={<span className="text-[1.2rem] md:text-[2rem]"></span>}
          close={closeModal}
          posterSrc="/images/pages/index/addictive-section/video_poster.webp"
          videoSrc="/video/game-trailer-2.mp4"
        />
      )}

      <section className="flex flex-col">
        <Text as="h2" className="text-center my-8 md:mt-0">
          Addictive Gameplay
        </Text>

        <BackgroundImage
          imageSrc="/images/pages/index/addictive-section/video_poster.webp"
          className="bg-center relative overflow-hidden"
        >
          <Box className="h-[78px] scale-x-[-1.1] w-full bg-cover bg-left absolute top-0 left-0 right-[-2px] bg-[url(/images/pages/index/description-section/top-border.svg)] z-1" />
          <Box className="h-[78px] scale-x-[-1.1] w-full bg-cover bg-right absolute bottom-0 left-0 right-0 bg-[url(/images/pages/index/description-section/bottom-border.svg)] z-1" />
          <Box className="absolute inset-0 bg-gradient-to-r from-[#390090dd] via-[#39009000] z-2" />

          <Section className="py-40 z-3">
            <Box center>
              <PlayVideoButton onClick={openModal} className="w-[15rem] h-[15rem] cursor-pointer" />
            </Box>
          </Section>
        </BackgroundImage>

        <Box className="mt-10 mx-auto">
          <Link href={Links.GAME}>
            <Box
              style={{
                boxShadow:
                  "0px 4px 16px rgba(255, 102, 81, 0.5), inset 0px -1px 1px rgba(180, 16, 46, 0.25), inset 0px 1px 1px rgba(255, 255, 255, 0.5)",
              }}
              className="text-white uppercase py-3.5 px-8 w-full md:w-auto font-semibold text-shadow-without-blur rounded-sm bg-gradient-to-r from-[#FFB80C] to-[#FF109D] "
            >
              START PLAY TO EARN
            </Box>
          </Link>
        </Box>
      </section>
    </>
  )
}
