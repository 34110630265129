import type { CookieSerializeOptions } from "cookie"
import { serialize } from "cookie"
import type { GetServerSidePropsContext } from "next/types"

export const UTM_COOKIE_NAME = "utm_cryptobots"
export const URLS_FOR_CHECK_UTM = ["/game"]
export const UTM_QUERY_NAMES = ["utm_campaign", "utm_source", "utm_content", "utm_term", "utm_medium"]

export function isUrlForUtm(resolvedUrl: string, urlsForCheckUtm: Array<string>) {
  return urlsForCheckUtm.includes(new URL(`https://temp${resolvedUrl}`).pathname)
}

export function getUtm(queryParams: URLSearchParams, utmQueryNames: Array<string>) {
  const utm: Record<string, string> = {}

  utmQueryNames.forEach(utmName => {
    const utmParamValue = queryParams.get(utmName)

    if (utmParamValue) {
      utm[utmName] = utmParamValue
    }
  })

  return utm
}

export function saveUtmToCookie(
  context: GetServerSidePropsContext,
  utmQueryNames: Array<string> = [],
  cookieName: string,
  cookieOptions: CookieSerializeOptions = {},
) {
  const queryParams = new URL(`https://temp${context.resolvedUrl}`).searchParams

  const utm = getUtm(queryParams, utmQueryNames)

  if (Object.keys(utm).length > 0) {
    if (typeof cookieOptions.maxAge === "number") {
      cookieOptions.expires = new Date(Date.now() + cookieOptions.maxAge * 1000)
    }

    context.res.setHeader("Set-Cookie", serialize(cookieName, new URLSearchParams(utm).toString(), cookieOptions))
  }
}
